/* eslint-disable jsx-a11y/control-has-associated-label */

import React from "react"

import { Layout, Seo, PageSection, classes } from "../../layout"
import { IPhone, FindOutMoreButton, img } from "../../components"

export default function IndexPage(props) {
  const { heading, heading2, iphone, sectionText, paragraph } = classes
  return (
    <Layout path={props.path}>
      <Seo title="Trailguide resort solutions" />

      <h1
        className={heading + " mx-auto mt-20 md:mt-40 max-w-4xl px-6 md:px-0"}
      >
        Trailguide resort solutions
      </h1>

      <div className="mx-auto max-w-4xl my-8 md:my-24 px-6 md:px-0">
        <p className={paragraph}>
          Do you want to give your guests the best possible outdoor experience
          they can have while visiting your resort or hotel? Our digital
          solutions will help you achieve just this.
        </p>
        <p className={paragraph}>
          By using our solutions for digitalization and immediate accessibility
          you can offer your guests a premium stay at your wonderful resort.
        </p>
        <p className={paragraph}>
          Your guests will be happier and leave contentful, letting their
          friends know about your great place. Make sure your marketing funds
          are not lost on bad experiences.
        </p>
      </div>

      <PageSection>
        <h2 className={heading2}>
          Give your guests the perfect tool to explore
        </h2>
        <div className={sectionText}>
          <div>
            <p className="text-base sm:text-lg md:text-xl mb-4 md:mb-6">
              <b>Lack of local knowledge</b>
              <br />
              Your employees working in the reception might not have a complete
              knowledge of the tours around your place and guests might not be
              used to using paper based maps.
            </p>
            <p className="text-base sm:text-lg md:text-xl mb-4 md:mb-6">
              <b>Immediate access</b>
              <br />
              Give your guests easy and immediate access to relevant tour
              information, maps, and GPS navigation through their smart phones.
              The Trailguide App is completely free for your guests to use.
            </p>
            <p className="text-base sm:text-lg md:text-xl mb-4 md:mb-6">
              <b>Add your own content</b>
              <br />
              It is free for you as a destination to add bike-related content,
              and we offer solutions for you to add non-biking related content
              as well.
            </p>
            <p className={paragraph}>
              <b>Just scan a QR code</b>
              <br />
              Create collections of tours and activities specifically suited to
              your guests. Print QR codes for the collections and place them in
              your reception.
            </p>
            <FindOutMoreButton
              className="shadow-md sm:border-2 border-green-100"
              to="/products/app"
            >
              Find out more about the Trailguide App
            </FindOutMoreButton>
          </div>
          <IPhone
            alt="The trailguide app"
            src={img("/website/trailguide-iphone-world-4.jpeg")}
            className={`hidden sm:block ${iphone}`}
          />
        </div>
      </PageSection>

      <PageSection>
        <h2 className={heading2}>Take control over the content you present</h2>
        <div className={sectionText}>
          <div>
            <p className="text-base sm:text-lg md:text-xl mb-4 md:mb-6">
              <b>Do not dissappoint</b>
              <br />
              Do not let your guests get lost on badly marked and overgrown
              trails. They might let their friends know about their bad
              experience and damage your reputation.
            </p>
            <p className="text-base sm:text-lg md:text-xl mb-4 md:mb-6">
              <b>Quality and correctness</b>
              <br />
              Make sure all the information you present to your guests is
              correct and up to date. With our Trailguide platform you can take
              control over the content you present on all your digital channels.
            </p>
            <p className="text-base sm:text-lg md:text-xl mb-4 md:mb-6">
              <b>Website integration</b>
              <br />
              Integrate your tour content into your websites directly from the
              Trailguide database with our powerful integration module. It is
              simple, flexible and fast.
            </p>
            <FindOutMoreButton
              className="shadow-md sm:border-2 border-green-100"
              to="/products/plugin"
            >
              Find out more about integration
            </FindOutMoreButton>
          </div>
        </div>
      </PageSection>

      <PageSection>
        <h2 className={heading2}>
          Make sure you are visible to potential guests
        </h2>
        <div className={sectionText}>
          <div>
            <p className={paragraph}>
              <b>Attracting users</b>
              <br />
              Trailguide has tens of thousands of users per week looking for
              outdoor activities and new places to explore.
            </p>
            <p className={paragraph}>
              <b>Visibility</b>
              <br />
              Make sure your place is visible and can be found by users of our
              free Trailguide app and perhaps visit you. Your resort will be
              clearly visible on the map with your logo and attract attention to
              it and the activities you offer.
            </p>
            <FindOutMoreButton
              className="shadow-md sm:border-2 border-green-100"
              to="/products/presence"
            >
              Find out more
            </FindOutMoreButton>
            <p className={paragraph}>
              <b>Landing page</b>
              <br />
              With your own landing page on the Trailguide app, potential guests
              will immediately see what you have to offer. These can include
              tours and activities not found in the public Trailguide app.
            </p>
            <FindOutMoreButton
              className="shadow-md sm:border-2 border-green-100"
              to="/products/landingpage"
            >
              Find out more
            </FindOutMoreButton>
          </div>
          <div className="hidden sm:block">
            <IPhone
              alt="Ensure your presence on Trailguide"
              src={img("/website/trailguide-iphone-landingpage-storefjell.png")}
              className={iphone}
            />
          </div>
        </div>
      </PageSection>

      <PageSection>
        <table className="w-full mb-24">
          <tbody>
            <tr>
              <th className="px-4 py-3 text-left bg-gray-300 whitespace-no-wrap">
                Trailguide resort solutions
              </th>
              <th className="px-4 py-3 bg-gray-300"></th>
              <th className="px-4 py-3 bg-gray-300">Small</th>
              <th className="px-4 py-3 bg-gray-300">Large</th>
            </tr>
            <tr>
              <td className="h-4"></td>
            </tr>
            <tr>
              <td className="px-4 py-1">Integration module</td>
              <td className="px-4 py-1 text-right whitespace-no-wrap">
                Kr 5.000
              </td>
              <td className="px-4 py-1 text-center text-green-600 font-bold"></td>
              <td className="px-4 py-1 text-center text-green-600 font-bold">
                ✓
              </td>
            </tr>
            <tr>
              <td className="px-4 py-1">Activity manager</td>
              <td className="px-4 py-1 text-right">Kr 5.000</td>
              <td className="px-4 py-1 text-center text-green-600 font-bold">
                ✓
              </td>
              <td className="px-4 py-1 text-center text-green-600 font-bold">
                ✓
              </td>
            </tr>
            <tr>
              <td className="px-4 py-1">Collection manager</td>
              <td className="px-4 py-1 text-right">Kr 5.000</td>
              <td className="px-4 py-1 text-center text-green-600 font-bold">
                ✓
              </td>
              <td className="px-4 py-1 text-center text-green-600 font-bold">
                ✓
              </td>
            </tr>
            <tr>
              <td className="px-4 py-1 whitespace-no-wrap">
                Visibility in the Trailguide App
              </td>
              <td className="px-4 py-1 text-right whitespace-no-wrap">
                Kr 8.000
              </td>
              <td className="px-4 py-1 text-center text-green-600 font-bold">
                ✓
              </td>
              <td className="px-4 py-1 text-center text-green-600 font-bold">
                ✓
              </td>
            </tr>
            <tr>
              <td className="px-4 py-1">Resort landing page</td>
              <td className="px-4 py-1 text-right">Kr 5.000</td>
              <td className="px-4 py-1 text-center"></td>
              <td className="px-4 py-1 text-center text-green-600 font-bold">
                ✓
              </td>
            </tr>
            <tr>
              <td className="px-4 py-1">Total activity count (25 free)</td>
              <td className="px-4 py-1 text-right">Kr 100</td>
              <td className="px-4 py-1 text-center">25</td>
              <td className="px-4 py-1 text-center">75</td>
            </tr>
            <tr>
              <td className="px-4 py-1">Portal user accounts (1 free)</td>
              <td className="px-4 py-1 text-right">Kr 2.000</td>
              <td className="px-4 py-1 text-center">1</td>
              <td className="px-4 py-1 text-center">2</td>
            </tr>
            <tr>
              <td className="p-4 font-bold">Price</td>
              <td className="p-4"></td>
              <td className="p-4 whitespace-no-wrap">Kr 18.000</td>
              <td className="p-4 whitespace-no-wrap">Kr 35.000</td>
            </tr>
          </tbody>
          <caption
            className="text-sm p-4 bg-gray-200"
            style={{ captionSide: "bottom" }}
          >
            Prices are per year and excluding VAT. There is an additional setup
            fee that includes work and training. The solution is flexible and
            can be adjusted to your custom needs. For prices is EUR and CHF,
            simply divide by ten. Contact sales
            <span className="hidden">If you see this, apply for a job</span>@
            <span className="hidden">as a programmer at Trailguide</span>
            trailguide.no for more information
          </caption>
        </table>
      </PageSection>
    </Layout>
  )
}
